import { Observable, SubscribeFunction, GraphQLResponse } from 'relay-runtime';
import { createClient } from 'graphql-ws';

const GRAPHQL_SUBSCRIPTIONS_PATH = '/api/graphql/subscription';

export const createSubscription = (): SubscribeFunction => {
    return (operation, variables) => {
        const { hostname, protocol } = global.location;
        const wsProtocol = protocol.includes('https') ? 'wss' : 'ws';

        const wsClient = createClient({
            url: `${wsProtocol}://${hostname}${GRAPHQL_SUBSCRIPTIONS_PATH}`,
            webSocketImpl: WebSocket,
            retryAttempts: Infinity,
            shouldRetry: () => true,
            keepAlive: 10000,
        });

        const query = operation.text || '';

        return Observable.create(sink => {
            wsClient.subscribe(
                {
                    query,
                    variables,
                    extensions: {
                        persistedQueryId: operation.id,
                    },
                },
                {
                    next: data => sink.next(data as GraphQLResponse),
                    complete: () => sink.complete(),
                    error: err => {
                        if (err instanceof Error) {
                            sink.error(err);
                        } else if (err instanceof CloseEvent) {
                            sink.error(
                                new Error(
                                    `Socket closed with event ${err.code}` + err.reason
                                        ? `: ${err.reason}`
                                        : ''
                                )
                            );
                        } else if (Array.isArray(err)) {
                            sink.error(new Error(err.map(({ message }) => message).join(', ')));
                        }
                    },
                }
            );

            return wsClient.dispose;
        });
    };
};
