import { FC, useEffect, useState } from 'react';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';
import AccountCircle from '@mui/icons-material/AccountCircle';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useRouteIsChanging, useUserToken } from '@ktech/components';

export const userNavigation = [
    {
        href: '/my/tournament/',
        key: 'my-tournament',
        label: <FormattedMessage defaultMessage="My Tournaments" id="FXL3Ej" />,
    },
    {
        href: '/my/organization/',
        key: 'organization',
        label: <FormattedMessage defaultMessage="My Organizations" id="3DfXqC" />,
    },
    {
        href: '/my/account/personal-data/',
        key: 'personal-data',
        label: <FormattedMessage defaultMessage="My Account" id="2bGejb" />,
        hasDivider: true,
    },
    {
        href: '/api/sign-out/',
        key: 'sign-out',
        label: <FormattedMessage defaultMessage="Sign Out" id="F62y+K" />,
        browserLink: true,
    },
];

const UserNavigation: FC = () => {
    const userToken = useUserToken();
    const isRouteChanging = useRouteIsChanging();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    useEffect(() => {
        setAnchorEl(null);
    }, [isRouteChanging]);

    return (
        <>
            {userToken ? (
                <Button
                    variant="text"
                    onClick={e => setAnchorEl(e.currentTarget)}
                    color={userToken.masquerade ? 'error' : 'inherit'}
                >
                    <AccountCircle />
                    <Box marginLeft={1}>
                        {userToken.firstName} {userToken.lastName}
                    </Box>
                </Button>
            ) : (
                <Button variant="text" color="inherit" LinkComponent={Link} href="/auth/sign-in/">
                    <AccountCircle sx={{ marginRight: 1 }} />
                    <FormattedMessage defaultMessage="Sign In" id="Ub+AGc" />
                </Button>
            )}
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
            >
                {userNavigation.map(({ href, label, hasDivider, browserLink }) => [
                    <MenuItem key={href} component={browserLink ? 'a' : Link} href={href}>
                        {label}
                    </MenuItem>,
                    hasDivider && <Divider />,
                ])}
            </Menu>
        </>
    );
};

export default UserNavigation;
