import { graphql } from 'relay-runtime';

export const AppQuery = graphql`
    query AppQueryQuery($userEntityId: Int!, $hasUserEntityId: Boolean!) {
        user(entityId: $userEntityId) @include(if: $hasUserEntityId) {
            entityId
            firstName
            lastName
            fullName
            email
        }
    }
`;
