import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import { CreateRelayEnvironmentArgs } from './types';
import { createFetcher } from './createFetcher';
import { createSubscription } from './createSubscription';

export const createRelayEnvironment = (args?: CreateRelayEnvironmentArgs) => {
    const environment: Environment = new Environment({
        network: Network.create(createFetcher(args), createSubscription()),
        store: new Store(new RecordSource(args?.records)),
    });
    return environment;
};
