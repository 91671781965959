import { FC, ReactNode, useRef, useState, /* useEffect, */ useCallback } from 'react';
import {
    Button,
    Dialog,
    DialogProps,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
    title: {
        defaultMessage: 'Are you sure?',
        id: '2oCaym',
    },
    confirmCta: {
        defaultMessage: 'Yes',
        id: 'a5msuh',
    },
    rejectCta: {
        defaultMessage: 'No',
        id: 'oUWADl',
    },
});

type OptionsType = {
    title?: ReactNode;
    confirmCta?: ReactNode;
    rejectCta?: ReactNode;
};

type ShowOptionsType = {
    title?: ReactNode;
    confirmCta?: ReactNode;
    rejectCta?: ReactNode;
};

export type ConfirmDialogProps = {
    children?: ReactNode;
    size?: DialogProps['maxWidth'];
};

export const useConfirmDialog = (options?: OptionsType) => {
    const intl = useIntl();
    const [title, setTitle] = useState(options?.title || intl.formatMessage(messages.title));
    const [confirmCta, setConfirmCta] = useState<ReactNode>(
        options?.confirmCta || intl.formatMessage(messages.confirmCta)
    );
    const [rejectCta, setRejectCta] = useState<ReactNode>(
        options?.rejectCta || intl.formatMessage(messages.rejectCta)
    );
    const resolveRef = useRef<((value: boolean) => void) | null>(null);
    const [isOpen, setIsOpen] = useState(false);

    const confirm = (showOptions: ShowOptionsType = {}) => {
        setTitle(showOptions.title || options?.title || intl.formatMessage(messages.title));
        setConfirmCta(
            showOptions.confirmCta || options?.confirmCta || intl.formatMessage(messages.confirmCta)
        );
        setRejectCta(
            showOptions.rejectCta || options?.rejectCta || intl.formatMessage(messages.rejectCta)
        );

        setIsOpen(true);

        return new Promise<boolean>(resolve => {
            resolveRef.current = resolve;
        });
    };

    const _resolve = useCallback(() => {
        if (resolveRef.current) {
            resolveRef.current(true);
        }
        setIsOpen(false);
    }, []);

    const _reject = useCallback(() => {
        if (resolveRef.current) {
            resolveRef.current(false);
        }
        setIsOpen(false);
    }, []);

    // const handleKeydown = useCallback(
    //     (e: KeyboardEvent) => {
    //         switch (e.key) {
    //             case 'Enter': {
    //                 _resolve();
    //                 break;
    //             }
    //             default:
    //                 break;
    //         }
    //     },
    //     [_resolve]
    // );

    // useEffect(() => {
    //     if (isOpen) {
    //         // document.addEventListener('keydown', handleKeydown);
    //     }

    //     return () => {
    //         document.removeEventListener('keydown', handleKeydown);
    //     };
    // }, [isOpen, handleKeydown]);

    const ConfirmDialog: FC<ConfirmDialogProps> = ({ children, size = 'xs' }) => (
        <Dialog open={isOpen} onClose={_reject} fullWidth maxWidth={size}>
            <DialogTitle>{title}</DialogTitle>
            {children && <DialogContent>{children}</DialogContent>}
            <DialogActions>
                <Button variant="outlined" onClick={_reject}>
                    {rejectCta}
                </Button>
                <Button onClick={_resolve} autoFocus>
                    {confirmCta}
                </Button>
            </DialogActions>
        </Dialog>
    );

    return {
        confirm,
        isOpen,
        ConfirmDialog,
    };
};
