import { FC } from 'react';
import { useCrisp } from './hooks/useCrisp';

type Props = Parameters<typeof useCrisp>[0];

const CrispLoader: FC<Props> = props => {
    useCrisp(props);
    return null;
};

export default CrispLoader;
