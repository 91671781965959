import { RequestParameters, PayloadError } from 'relay-runtime';

type ClientErrorType = {
    clientError?: {
        code?: number;
        status?: string;
        message?: string;
    };
};
export class KTRelayNetworkError extends Error {
    public source?: {
        errors?: (PayloadError & ClientErrorType)[] | null;
    } | null;
}

export function createNetworkError(
    operation: RequestParameters,
    errors: (PayloadError & ClientErrorType)[]
): KTRelayNetworkError {
    const error: KTRelayNetworkError = new KTRelayNetworkError('KTRelayNetworkLayer');
    const errorsMessage = errors
        ? errors.map(({ message }, index) => `${index + 1}. ${message}`).join('\n')
        : '(No errors)';

    error.message = `No data returned for operation '${operation.name}' got error(s):\n${errorsMessage}\n\nSee the error 'source' property for more information.`;

    error.source = {
        errors,
    };

    return error;
}
