import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useRouteIsChanging } from '@ktech/components';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';

const LocaleNavigation: FC = () => {
    const router = useRouter();
    const isRouteChanging = useRouteIsChanging();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    useEffect(() => {
        setAnchorEl(null);
    }, [isRouteChanging]);

    return (
        <>
            <Button variant="text" color="inherit" onClick={e => setAnchorEl(e.currentTarget)}>
                {router.locale}
            </Button>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem component={Link} href={router.asPath} locale="en">
                    EN
                </MenuItem>
                <MenuItem component={Link} href={router.asPath} locale="lt">
                    LT
                </MenuItem>
                <MenuItem component={Link} href={router.asPath} locale="pl">
                    PL
                </MenuItem>
                <MenuItem component={Link} href={router.asPath} locale="ru">
                    RU
                </MenuItem>
            </Menu>
        </>
    );
};

export default LocaleNavigation;
