import '@ktech/styles/muiGlobals.scss';

import App, { AppProps, AppInitialProps, AppContext } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { GoogleTagManager } from '@next/third-parties/google';
import { IntlProvider } from 'react-intl';
import { RelayEnvironmentProvider, Environment, fetchQuery } from 'react-relay';
import { useMemo, useState, useCallback, Suspense } from 'react';

import { AppCacheProvider } from '@mui/material-nextjs/v13-pagesRouter';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { createKTTheme } from '@ktech/styles/createKTTheme';
import {
    FeatureFlagProvider,
    FeatureFlagProviderQuery,
    FeatureFlagProviderQueryType,
} from '@ktech/components/src/FeatureFlagProvider';
import { UserTokenProvider } from '@ktech/components';
import CrispLoader from '@ktech/components/src/CrispLoader';

import { createRelayEnvironment } from '@ktech/relay';
import { CookieConsentProvider } from '@ktech/cookie-jar/CookieConsent';
import { ContentStyled } from '../components/general/Content';
import Navigation from '../components/layout/Navigation';
import Footer from '../components/layout/Footer';

import English from '@ktech/locales/src/en.json';
import Russian from '@ktech/locales/src/ru.json';
import Lithuanian from '@ktech/locales/src/lt.json';
import Polish from '@ktech/locales/src/pl.json';

import { NotificationsProvider } from '@ktech/components/src/Notifications';
import { RecordMap } from 'relay-runtime/lib/store/RelayStoreTypes';
import { useAfterRenderEffect } from '@ktech/components';
import { AppQuery } from '@/queries/AppQuery';
import { AppQueryQuery } from '@/queries/__generated__/AppQueryQuery.graphql';
import {
    decodeUserToken,
    getTokenFromCookie,
    getUserTokenFromIncomingMessage,
} from '@ktech/cookie-jar';

import dynamic from 'next/dynamic';
import GoogleAnalyticsLoader from '@/components/general/GoogleAnalyticsLoader';
const BulkTournamentBanner = dynamic(() => import('../components/marketing/BulkTournamentBanner'), {
    ssr: false,
});

const localeMap = new Map([
    ['en', English],
    ['lt', Lithuanian],
    ['ru', Russian],
    ['pl', Polish],
]);

type AppOwnProps = {
    records?: RecordMap;
    featureFlagData?: FeatureFlagProviderQueryType['response'];
    user?: {
        entityId: number;
        email: string;
        firstName: string;
        lastName: string;
        fullName: string;
    } | null;
};

function PageApp(props: AppProps<AppOwnProps>) {
    const { Component, pageProps } = props;
    const user = pageProps.user;
    const router = useRouter();
    const { locale = 'en' } = router;

    const [environment, setEnvironment] = useState<Environment>(
        createRelayEnvironment({
            searchParams: { locale },
            records: pageProps.records,
        })
    );

    useAfterRenderEffect(
        useCallback(() => {
            setEnvironment(environment =>
                createRelayEnvironment({
                    searchParams: { locale },
                    records: environment.getStore().getSource().toJSON(),
                })
            );
        }, [locale])
    );

    const messages = useMemo(() => {
        return localeMap.get(locale) || localeMap.get('en');
    }, [locale]);

    return (
        <AppCacheProvider {...props}>
            <IntlProvider locale={locale} messages={messages} onError={() => null}>
                <Head>
                    <title>KumiteTechnology</title>
                </Head>
                {process.env.NEXT_PUBLIC_GTM_ID && (
                    <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GTM_ID} />
                )}
                <CookieConsentProvider>
                    <GoogleAnalyticsLoader />
                    <ThemeProvider theme={createKTTheme()}>
                        <CssBaseline />

                        <RelayEnvironmentProvider environment={environment}>
                            <FeatureFlagProvider featureFlagData={pageProps.featureFlagData}>
                                <UserTokenProvider>
                                    <Suspense>
                                        <BulkTournamentBanner />
                                    </Suspense>
                                    <NotificationsProvider>
                                        <Navigation user={pageProps.user} />
                                        <ContentStyled>
                                            <Component {...pageProps} />
                                        </ContentStyled>
                                        <Footer />
                                    </NotificationsProvider>
                                </UserTokenProvider>
                            </FeatureFlagProvider>
                        </RelayEnvironmentProvider>
                        <CrispLoader
                            locale={locale}
                            nickname={user?.fullName}
                            email={user?.email}
                        />
                    </ThemeProvider>
                </CookieConsentProvider>
            </IntlProvider>
        </AppCacheProvider>
    );
}

PageApp.getInitialProps = async (context: AppContext): Promise<AppOwnProps & AppInitialProps> => {
    const ctx = await App.getInitialProps(context);

    const userToken =
        getUserTokenFromIncomingMessage(context.ctx.req) ||
        (global.document && getTokenFromCookie(global.document.cookie)) ||
        null;

    const decodedToken = decodeUserToken(userToken);
    const environment = createRelayEnvironment({
        nodeServiceHost: context.ctx.req?.headers.host || process.env.NEXT_PUBLIC_NODE_SERVICE_HOST,
        authorization: userToken,
    });

    const query = await fetchQuery<AppQueryQuery>(environment, AppQuery, {
        userEntityId: decodedToken?.entityId || 0,
        hasUserEntityId: !!decodedToken?.entityId,
    }).toPromise();

    const featureFlagData = await fetchQuery<FeatureFlagProviderQueryType>(
        environment,
        FeatureFlagProviderQuery,
        {}
    ).toPromise();

    return {
        ...ctx,
        pageProps: {
            ...ctx.pageProps,
            user: query?.user,
            featureFlagData,
        },
    };
};

export default PageApp;
