import { ChangeEvent, FC, ReactNode } from 'react';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import CheckCircle from '@mui/icons-material/CheckCircle';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import Cancel from '@mui/icons-material/Cancel';

import { Label, CheckboxStyled } from './CheckboxCard.styled';

export type CheckboxCardProps = {
    children: ReactNode;
    type?: 'radio' | 'checkbox';
    name: string;
    value: string;
    disabled?: boolean;
    icon?: 'add' | 'remove';
    onChange: (
        payload: { checked: boolean; value: string },
        event: ChangeEvent<HTMLInputElement>
    ) => void;
};

const CheckboxCard: FC<CheckboxCardProps> = ({
    children,
    type = 'checkbox',
    name,
    value,
    disabled,
    onChange,
    icon: iconProp = 'add',
}) => {
    let icon = <CheckCircleOutline />;
    let checkedIcon = <CheckCircle />;

    if (iconProp === 'remove') {
        icon = <CancelOutlined />;
        checkedIcon = <Cancel />;
    }

    return (
        <Label>
            <CheckboxStyled
                icon={icon}
                checkedIcon={checkedIcon}
                name={name}
                value={value}
                disabled={disabled}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    onChange({ checked: event.target.checked, value }, event);
                }}
                inputProps={{
                    type,
                    autoComplete: 'off',
                }}
            />
            {children}
        </Label>
    );
};

export default CheckboxCard;
