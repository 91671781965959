import { useState, FC, useEffect, createContext, ReactNode, useContext } from 'react';
import { useFormik } from 'formik';
import { FormattedMessage } from 'react-intl';
import {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Checkbox,
    FormControlLabel,
    Box,
    Typography,
    FormGroup,
    Link,
    IconButton,
    Modal,
    Paper,
} from '@mui/material';
import CookieIcon from '@mui/icons-material/Cookie';

const COOKIE_PREFERENCES_KEY = 'cookiePreferences';

type InitialValuesProp = {
    essential: boolean;
    analytics: boolean;
    functionality: boolean;
};

const getInitialValues = (): InitialValuesProp & {
    userDidAction: boolean;
} => {
    try {
        const storageData = JSON.parse(
            localStorage.getItem(COOKIE_PREFERENCES_KEY) || ''
        ) as unknown;

        if (
            storageData &&
            typeof storageData === 'object' &&
            'essential' in storageData &&
            'analytics' in storageData &&
            'functionality' in storageData
        ) {
            return { ...(storageData as InitialValuesProp), userDidAction: true };
        }
    } catch (e) {
        // Ignore errors
    }

    return {
        essential: true,
        analytics: false,
        functionality: false,
        userDidAction: false,
    };
};

export const CookieConsetContext = createContext<
    InitialValuesProp & {
        setConsent: (values: InitialValuesProp) => void;
    }
>({
    essential: true,
    analytics: false,
    functionality: false,
    setConsent: () => {},
});

export const CookieConsentProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [initialValues, setInitialValues] = useState<InitialValuesProp>({
        essential: true,
        analytics: false,
        functionality: false,
    });

    useEffect(() => {
        setInitialValues(getInitialValues());
    }, []);

    return (
        <CookieConsetContext.Provider value={{ ...initialValues, setConsent: setInitialValues }}>
            {children}
        </CookieConsetContext.Provider>
    );
};

const CookieConsent: FC = () => {
    const cookieConsent = useContext(CookieConsetContext);
    const [open, setOpen] = useState(false);
    const [preferencesOpen, setPreferencesOpen] = useState(false);

    // Formik form handler
    const formik = useFormik<InitialValuesProp>({
        initialValues: cookieConsent,
        enableReinitialize: true,
        onSubmit: values => {
            // Save cookie preferences to localStorage
            localStorage.setItem(COOKIE_PREFERENCES_KEY, JSON.stringify(values));
            cookieConsent.setConsent(values);
            // Close the dialog
            setOpen(false);
            setPreferencesOpen(false);
        },
    });

    useEffect(() => {
        if (!localStorage.getItem(COOKIE_PREFERENCES_KEY)) {
            setOpen(true);
        }
    }, []);

    // Handle accepting all cookies
    const handleAcceptAll = () => {
        formik.setValues({ essential: true, analytics: true, functionality: true });
        formik.handleSubmit();
    };

    // Handle rejecting non-essential cookies
    const handleRejectAll = () => {
        formik.setValues({ essential: true, analytics: false, functionality: false });
        formik.handleSubmit();
    };

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <CookieIcon />
            </IconButton>

            <Modal
                open={open}
                hideBackdrop
                disableScrollLock
                autoFocus
                component={Paper}
                sx={theme => ({
                    top: 'auto',
                    left: 'auto',
                    bottom: theme.spacing(2),
                    right: theme.spacing(2),
                    maxWidth: 600,
                })}
                onClose={(e, reason) => {
                    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                        return;
                    }
                    setOpen(false);
                }}
            >
                <>
                    <DialogTitle>
                        <FormattedMessage defaultMessage="Cookie Preferences" id="3DL9lN" />
                    </DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText>
                            <FormattedMessage
                                defaultMessage="We use cookies to improve your experience, personalize content, and analyze traffic. Essential cookies are always enabled. You can customize your preferences below. For more information, please review our <link>Cookie Policy</link>."
                                id="Cr9yy4"
                                values={{
                                    link: chunks => (
                                        <Link
                                            href="/docs/policy/cookie-policy"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {chunks}
                                        </Link>
                                    ),
                                }}
                            />
                        </DialogContentText>

                        {preferencesOpen ? (
                            <Box mt={2}>
                                <Typography variant="h6">
                                    <FormattedMessage
                                        defaultMessage="Customize Cookie Preferences"
                                        id="8tPaY5"
                                    />
                                </Typography>
                                <form onSubmit={formik.handleSubmit} id="cookie-consent-form">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={formik.values.essential}
                                                    disabled
                                                />
                                            }
                                            label={
                                                <FormattedMessage
                                                    defaultMessage="Essential Cookies (Always Active)"
                                                    id="KPGVG3"
                                                />
                                            }
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="analytics"
                                                    checked={formik.values.analytics}
                                                    onChange={formik.handleChange}
                                                />
                                            }
                                            label={
                                                <FormattedMessage
                                                    defaultMessage="Analytics Cookies"
                                                    id="kSEXjE"
                                                />
                                            }
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="functionality"
                                                    checked={formik.values.functionality}
                                                    onChange={formik.handleChange}
                                                />
                                            }
                                            label={
                                                <FormattedMessage
                                                    defaultMessage="Functionality Cookies"
                                                    id="02QUnN"
                                                />
                                            }
                                        />
                                    </FormGroup>
                                </form>
                            </Box>
                        ) : (
                            <Box mt={2}>
                                <Typography variant="body1">
                                    <FormattedMessage
                                        defaultMessage="By clicking “Accept All,” you agree to our use of cookies. You can also choose to accept only essential cookies or customize your preferences."
                                        id="NyCiUW"
                                    />
                                </Typography>
                            </Box>
                        )}
                    </DialogContent>

                    {preferencesOpen ? (
                        <DialogActions>
                            <Button
                                type="submit"
                                variant="contained"
                                form="cookie-consent-form"
                                key="save-preferences"
                            >
                                <FormattedMessage defaultMessage="Save Preferences" id="DSCPXP" />
                            </Button>
                        </DialogActions>
                    ) : (
                        <DialogActions>
                            <Button onClick={() => setPreferencesOpen(true)} variant="text">
                                <FormattedMessage defaultMessage="Customize" id="TXpOBi" />
                            </Button>

                            <Button onClick={handleRejectAll} variant="outlined">
                                <FormattedMessage defaultMessage="Reject All" id="htOj7a" />
                            </Button>

                            <Button onClick={handleAcceptAll} variant="contained">
                                <FormattedMessage defaultMessage="Accept All" id="O5s0jK" />
                            </Button>
                        </DialogActions>
                    )}
                </>
            </Modal>
        </>
    );
};

export default CookieConsent;
