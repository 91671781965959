import { createContext, FC, ReactNode, useContext } from 'react';
import { graphql } from 'relay-runtime';
import { FeatureFlagProviderQuery$data } from './__generated__/FeatureFlagProviderQuery.graphql';

import { FeatureFlagProviderQuery as FeatureFlagProviderQueryType } from './__generated__/FeatureFlagProviderQuery.graphql';
export type { FeatureFlagProviderQueryType };

export const FeatureFlagProviderQuery = graphql`
    query FeatureFlagProviderQuery {
        featureFlagsForUser {
            key
            isActive
        }
    }
`;

const FeatureFlagContext = createContext<{ isFFEnabled: (key: string) => boolean }>({
    isFFEnabled: () => false,
});

export const useFeatureFlag = () => {
    const ctx = useContext(FeatureFlagContext);

    if (!ctx) {
        throw Error(
            'The `useFeatureFlag` hook must be called from a descendent of the `FeatureFlagProvider`.'
        );
    }

    return ctx;
};

export const FeatureFlagProvider: FC<{
    featureFlagData?: FeatureFlagProviderQuery$data;
    children: ReactNode;
}> = ({ featureFlagData, children }) => {
    const isFFEnabled = (key: string): boolean =>
        !!featureFlagData &&
        featureFlagData.featureFlagsForUser.some(
            featureFlag => featureFlag?.key === key && featureFlag.isActive
        );

    return (
        <FeatureFlagContext.Provider value={{ isFFEnabled }}>
            {children}
        </FeatureFlagContext.Provider>
    );
};
