import {
    FetchFunction,
    GraphQLResponseWithData,
    GraphQLResponseWithoutData,
    Observable,
} from 'relay-runtime';
import fetchMultipart from 'fetch-multipart-graphql';
import { getTokenFromCookie } from '@ktech/cookie-jar';

import { createNetworkError } from './createNetworkError';
import { Authorizations, CreateRelayEnvironmentArgs, GraphQLRequestBody } from './types';

const GRAPHQL_PATH = '/api/graphql/';
const QUERY_STRING_HEADER = 'x-kt-query-params';

const getAuthorizationHeaders = ({
    authorization,
    tournamentAdminAuthorization,
}: Authorizations = {}) => {
    let ktAuthorization = '';
    let ktTournamentAdminAuthorization = '';

    try {
        ktAuthorization =
            authorization === null
                ? ''
                : authorization || getTokenFromCookie(document.cookie) || '';
    } catch (e) {
        // console.error(e);
    }

    try {
        ktTournamentAdminAuthorization =
            tournamentAdminAuthorization === null
                ? ''
                : tournamentAdminAuthorization ||
                  getTokenFromCookie(document.cookie, { getTournamentToken: true }) ||
                  '';
    } catch (e) {
        // console.error(e);
    }

    return {
        'kt-authorization': ktAuthorization,
        'kt-tournament-admin-authorization': ktTournamentAdminAuthorization,
    };
};

export const createFetcher =
    ({
        authorization,
        tournamentAdminAuthorization,
        nodeServiceHost = '',
        referer,
        searchParams,
    }: Pick<CreateRelayEnvironmentArgs, 'nodeServiceHost' | 'referer' | 'searchParams'> &
        Authorizations = {}): FetchFunction =>
    (operation, variables) => {
        const operations: GraphQLRequestBody = { variables };
        if (operation.id) {
            // persisted query support
            operations.id = operation.id;
        } else {
            operations.query = operation.text;
        }

        if (nodeServiceHost && !nodeServiceHost.startsWith('http')) {
            nodeServiceHost = `https://${nodeServiceHost}`;
        }

        const urlString = `${nodeServiceHost}${GRAPHQL_PATH}`;
        const urlParams = new URLSearchParams(searchParams);
        const headers = {
            [QUERY_STRING_HEADER]: urlParams.toString(),
            'content-type': 'application/json',
            referer: referer || '',
            ...getAuthorizationHeaders({ authorization, tournamentAdminAuthorization }),
        };
        return Observable.create(sink => {
            fetchMultipart<GraphQLResponseWithData | GraphQLResponseWithoutData>(urlString, {
                method: 'POST',
                headers,
                credentials: 'same-origin',
                body: JSON.stringify(operations),
                onNext: payloads => {
                    let hasErrors = false;
                    for (const payload of payloads) {
                        if (payload.errors) {
                            hasErrors = true;
                            // all errors are critical, throw so relay treats as an error
                            const error = createNetworkError(operation, payload.errors);
                            sink.error(error);
                        }
                    }
                    if (!hasErrors) {
                        sink.next(payloads);
                    }
                },
                onError: err => sink.error(err as Error),
                onComplete: () => sink.complete(),
            });
        });
    };
