import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export const useRouteIsChanging = () => {
    const router = useRouter();
    const [isLoading, setIsLoading] = useState(false);

    const startProgress = () => setIsLoading(true);
    const stopProgress = () => setIsLoading(false);

    useEffect(() => {
        router.events.on('routeChangeStart', startProgress);
        router.events.on('routeChangeComplete', stopProgress);
        router.events.on('routeChangeError', stopProgress);
        return () => {
            router.events.off('routeChangeStart', startProgress);
            router.events.off('routeChangeComplete', stopProgress);
            router.events.off('routeChangeError', stopProgress);
        };
    }, [router]);

    return isLoading;
};
