import { FC } from 'react';
import { LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import isPropValid from '@emotion/is-prop-valid';

import { useRouteIsChanging } from './hooks/useRouteIsChanging';

const ProgressBar = styled(LinearProgress, { shouldForwardProp: isPropValid })<{
    $isVisible: boolean;
}>(({ $isVisible, theme }) => ({
    height: $isVisible ? 2 : 0,
    opacity: $isVisible ? 0.7 : 0,
    transition: theme.transitions.create('visible'),
}));

const RouteChangingProgressBar: FC = () => {
    const isRouteChanging = useRouteIsChanging();
    return <ProgressBar $isVisible={isRouteChanging} />;
};

export default RouteChangingProgressBar;
