import { Palette, PaletteMode } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { printingComponentsTheme } from './printingComponentsTheme';

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xxl: true;
    }
}

export const createKTTheme = (payload?: {
    mode?: PaletteMode;
    typography?: TypographyOptions | ((palette: Palette) => TypographyOptions);
}) => {
    const mode = payload?.mode || 'dark';

    return createTheme({
        palette: {
            mode,
            background:
                mode === 'light'
                    ? {
                          default: '#fafafa',
                          paper: '#ffffff',
                      }
                    : {
                          default: '#303030',
                          paper: '#111111',
                      },
        },
        typography: payload?.typography,
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
                xxl: 1710,
            },
        },
        components: {
            ...printingComponentsTheme(),
            MuiContainer: {
                defaultProps: {
                    maxWidth: 'xxl',
                },
            },
            MuiFilledInput: {
                defaultProps: {
                    disableUnderline: true,
                },
                styleOverrides: {
                    root: ({ theme }) => ({
                        borderRadius: theme.shape.borderRadius,
                        '& .MuiSelect-icon:has(+ .MuiInputAdornment-root)': {
                            position: 'inherit',
                            right: 0,
                        },
                    }),
                },
            },
            MuiTextField: {
                defaultProps: { variant: 'filled' },
            },
            MuiInputLabel: {
                defaultProps: { variant: 'filled' },
            },
            MuiButton: {
                defaultProps: { variant: 'contained' },
            },
            MuiSelect: {
                styleOverrides: {
                    root: {
                        '& .MuiSelect-icon:has(+ .MuiInputAdornment-root)': {
                            position: 'inherit',
                            right: 0,
                        },
                    },
                },
            },
        },
    });
};
