import { useEffect, useContext } from 'react';
import { Crisp, ChatboxPosition } from 'crisp-sdk-web';
import { CookieConsetContext } from '@ktech/cookie-jar/CookieConsent';

export const useCrisp = (options?: {
    disable?: boolean;
    locale?: string;
    nickname?: string;
    email?: string;
    position?: 'Left' | 'Right';
}) => {
    const cookieConsent = useContext(CookieConsetContext);

    useEffect(() => {
        if (!cookieConsent.functionality) {
            return;
        }

        if (!options?.disable && process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID) {
            Crisp.configure(process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID, {
                locale: options?.locale,
            });

            if (options?.position) {
                Crisp.setPosition(ChatboxPosition[options.position]);
            }
        }
    }, [cookieConsent.functionality, options?.disable, options?.locale, options?.position]);

    useEffect(() => {
        if (!cookieConsent.functionality) {
            return;
        }

        if (options?.nickname && options?.email) {
            Crisp.user.setNickname(options.nickname);
            Crisp.user.setEmail(options.email);
        }
    }, [cookieConsent.functionality, options?.nickname, options?.email]);
};
