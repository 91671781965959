import { useEffect, useRef } from 'react';
import type { EffectCallback } from 'react';

export function useAfterRenderEffect(effect: EffectCallback): void {
    const initialLoadRef = useRef(true);

    useEffect(() => {
        if (initialLoadRef.current) {
            initialLoadRef.current = false;
            return;
        }

        effect();
    }, [effect]);
}
