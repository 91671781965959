/*
 * A function that simulates the behavior of the following function,
 * but attempts to maintain the type of the input value:
 *
 * const filterFalsy = (value) => Boolean(value)
 *
 * And can be used like `arr.filter(Boolean)`:
 *
 * const truthy = arr.filter(filterFalsy)
 *
 */
export function filterFalsy<T>(
    value: T | null | number | undefined | string
): value is Exclude<T, undefined | null | '' | 0> {
    return (
        value !== null &&
        typeof value !== 'undefined' &&
        value !== 0 &&
        value !== '' &&
        !Number.isNaN(value as number)
    );
}
