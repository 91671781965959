import { usePathname } from 'next/navigation';
import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { getDecodedTokenFromCookie, USER_TOKEN } from '@ktech/cookie-jar';

const UserTokenContext = createContext<{ userToken: USER_TOKEN | null } | null>(null);

export const useUserToken = () => {
    const ctx = useContext(UserTokenContext);

    if (!ctx) {
        throw Error(
            'The `useUserToken` hook must be called from a descendent of the `UserTokenProvider`.'
        );
    }

    return ctx.userToken;
};

export const UserTokenProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const pathname = usePathname();
    const [decodedUserToken, setDecodedUserToken] = useState<USER_TOKEN | null>(null);

    useEffect(() => {
        setDecodedUserToken(getDecodedTokenFromCookie(document.cookie));
    }, [pathname]);

    return (
        <UserTokenContext.Provider value={{ userToken: decodedUserToken }}>
            {children}
        </UserTokenContext.Provider>
    );
};
