import { decodeJwt, JWTPayload } from 'jose';
import cookie, { serialize } from 'cookie';
import { IncomingMessage, ServerResponse } from 'http';

import { NextApiRequest, NextApiResponse } from 'next';
import { NextRequest } from 'next/server';

enum COOKIE {
    USER_TOKEN = '__ktUserToken',
    MASQUERADE_USER_TOKEN = '__ktMasqueradeUserToken',
    TOURNAMENT_ADMIN_TOKEN = '__ktTournamentAdminToken',
}

// const COOKIE_DOMAIN = '.kumitetechnology.com';

enum TOKEN_CLAIMS {
    SU = 'SU',
    USER = 'USER',
}

type BASE_USER_TOKEN = {
    entityId: number;
    email: string;
    firstName: string;
    lastName: string;
    masquerade?: boolean;
    claims: TOKEN_CLAIMS[];
};

export type USER_TOKEN = BASE_USER_TOKEN & {
    exp: number;
    iat: number;
    sub: string;
};

const isNextRequest = (payload: unknown): payload is NextRequest => {
    return payload?.constructor.name.includes('NextRequest') || false;
};

type OptionsType = { getTournamentToken?: boolean; getRealUserToken?: boolean };

export const getUserTokenFromIncomingMessage = (
    request: IncomingMessage | NextRequest | undefined,
    options?: OptionsType
): string | null => {
    if (isNextRequest(request)) {
        if (options?.getTournamentToken) {
            return request.cookies.get(COOKIE.TOURNAMENT_ADMIN_TOKEN)?.value || null;
        }

        return (
            (options?.getRealUserToken
                ? null
                : request.cookies.get(COOKIE.MASQUERADE_USER_TOKEN)?.value) ||
            request.cookies.get(COOKIE.USER_TOKEN)?.value ||
            null
        );
    }

    return getTokenFromCookie(request?.headers.cookie || '', options);
};

export const getDecodedCookieFromNextRequest = (
    request: NextRequest | NextApiRequest
): USER_TOKEN | null => {
    let tokenValue: string | undefined;
    if (isNextRequest(request)) {
        tokenValue = request.cookies.get(COOKIE.USER_TOKEN)?.value;
    } else {
        tokenValue = request.cookies[COOKIE.USER_TOKEN];
    }
    return decodeUserToken(tokenValue || null);
};

export const getDecodedCookieFromIncomingMessage = (
    request: IncomingMessage | NextRequest
): USER_TOKEN | null => {
    return decodeUserToken(getUserTokenFromIncomingMessage(request));
};

export const getDecodedTokenFromCookie = (cookieString: string): USER_TOKEN | null => {
    return decodeUserToken(getTokenFromCookie(cookieString));
};

export function getTokenFromCookie(cookieString: string, options?: OptionsType): string | null {
    const parsedCookie = cookie.parse(cookieString);

    if (options?.getTournamentToken) {
        return parsedCookie[COOKIE.TOURNAMENT_ADMIN_TOKEN] || null;
    }

    return (
        (options?.getRealUserToken ? null : parsedCookie[COOKIE.MASQUERADE_USER_TOKEN]) ||
        parsedCookie[COOKIE.USER_TOKEN] ||
        null
    );
}

const isUserToken = (decodedToken: JWTPayload): decodedToken is USER_TOKEN => {
    return (
        'entityId' in decodedToken &&
        !!decodedToken.claims &&
        Array.isArray(decodedToken.claims) &&
        decodedToken.claims.includes(TOKEN_CLAIMS.USER)
    );
};

export const decodeUserToken = (token: string | null): USER_TOKEN | null => {
    try {
        const decodedToken = decodeJwt(token || '');
        if (isUserToken(decodedToken)) {
            return decodedToken;
        }
    } catch (error) {
        // no empty enclosures
    }

    return null;
};

const SECONDS_IN_ONE_DAY = 24 * 60 * 60;
const ONE_DAY_AGO = -1 * 1000 * SECONDS_IN_ONE_DAY;

export const setMasqueradeToken = (
    res: NextApiResponse | ServerResponse<IncomingMessage>,
    accessToken: string
) => {
    res.setHeader(
        'Set-Cookie',
        serialize(COOKIE.MASQUERADE_USER_TOKEN, accessToken, {
            path: '/',
            // one hour
            expires: new Date(Date.now() + 60 * 60 * 1000),
        })
    );
};

export const removeUserToken = (
    req: IncomingMessage | NextApiRequest,
    res: NextApiResponse | ServerResponse<IncomingMessage>,
    options?: OptionsType
) => {
    const userToken = getDecodedCookieFromIncomingMessage(req);
    let cookieName = COOKIE.USER_TOKEN;

    if (options?.getTournamentToken) {
        cookieName = COOKIE.TOURNAMENT_ADMIN_TOKEN;
    } else if (userToken?.masquerade) {
        cookieName = COOKIE.MASQUERADE_USER_TOKEN;
    }

    const cookie = serialize(cookieName, '', {
        // domain: COOKIE_DOMAIN,
        path: '/',
        expires: new Date(Date.now() + ONE_DAY_AGO),
        maxAge: ONE_DAY_AGO / 1000,
    });

    res.setHeader('Set-Cookie', cookie);
};
