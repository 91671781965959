import { useEffect, useRef } from 'react';

export const useDOMReady = () => {
    const isDOMReady = useRef(false);

    useEffect(() => {
        isDOMReady.current = true;
    }, []);

    return isDOMReady.current;
};
