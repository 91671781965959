import Link from 'next/link';
import { FC, Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemIcon from '@mui/material/ListItemIcon';
import { ThemeProvider } from '@mui/material/styles';

import MenuIcon from '@mui/icons-material/Menu';
import { RouteChangingProgressBar, useRouteIsChanging } from '@ktech/components';
import { createKTTheme } from '@ktech/styles/createKTTheme';

import KTLogo from '@ktech/components/src/KTLogo';
import UserNavigation, { userNavigation } from './UserNavigation';
import LocaleNavigation from './LocaleNavigation';
import AccountCircle from '@mui/icons-material/AccountCircle';

type Props = {
    user?: {
        entityId: number;
        firstName: string;
        lastName: string;
    } | null;
};

const Navigation: FC<Props> = ({ user }) => {
    const isRouteChanging = useRouteIsChanging();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    // const { isTournamentRoute, isMyRoute } = useRouteMatcher();

    useEffect(() => {
        setIsDrawerOpen(false);
    }, [isRouteChanging]);

    return (
        <AppBar position="sticky">
            <Box position="relative">
                <Container maxWidth="xxl">
                    <Toolbar disableGutters>
                        <IconButton
                            onClick={() => setIsDrawerOpen(true)}
                            sx={{
                                display: {
                                    xs: 'flex',
                                    md: 'none',
                                },
                            }}
                        >
                            <MenuIcon />
                        </IconButton>

                        <Box component={Link} href="/" my={1} display="block">
                            <KTLogo style={{ width: 156 }} />
                        </Box>

                        <Box sx={{ flexGrow: 1 }} ml={3} display={{ xs: 'none', md: 'block' }}>
                            <Button
                                variant="text"
                                component={Link}
                                href="/"
                                color="inherit"
                                // variant={isTournamentRoute ? 'contained' : 'text'}
                            >
                                <FormattedMessage defaultMessage="Tournaments" id="yBFAhB" />
                            </Button>

                            {/* <Button
                                variant="text"
                                component={Link}
                                href="/about-us/"
                                color="inherit"
                                >
                                <FormattedMessage defaultMessage="About Us" />
                                </Button>
                                
                                <Button
                                variant="text"
                                component={Link}
                                href="/contacts/"
                                color="inherit"
                                >
                                <FormattedMessage defaultMessage="Contacts" />
                            </Button> */}
                        </Box>
                        <Box
                            sx={{ flexGrow: 0, justifySelf: 'flex-end' }}
                            display={{ xs: 'none', md: 'block' }}
                        >
                            <UserNavigation />
                            <LocaleNavigation />
                        </Box>
                    </Toolbar>
                </Container>

                <Box position="absolute" bottom={0} left={0} right={0}>
                    <RouteChangingProgressBar />
                </Box>
            </Box>
            <ThemeProvider theme={createKTTheme({ mode: 'dark' })}>
                <Drawer
                    open={isDrawerOpen}
                    variant="temporary"
                    anchor="left"
                    onClose={() => setIsDrawerOpen(false)}
                    PaperProps={{
                        sx: { position: 'inherit', displayPrint: 'none' },
                    }}
                >
                    <Toolbar>
                        <Box component={Link} href="/" my={1} display="block">
                            <KTLogo style={{ width: 156 }} />
                        </Box>
                    </Toolbar>
                    <Divider />
                    <List disablePadding sx={{ flex: 1 }}>
                        <ListItemButton component={Link} href="/">
                            <ListItemText>
                                <FormattedMessage defaultMessage="Tournaments" id="yBFAhB" />
                            </ListItemText>
                        </ListItemButton>
                        <Divider />
                    </List>
                    <List disablePadding>
                        <Divider />
                        {user ? (
                            <Fragment>
                                <ListSubheader
                                    sx={{ alignItems: 'center', display: 'flex', gap: 1 }}
                                >
                                    <AccountCircle />
                                    {user.firstName} {user.lastName}
                                </ListSubheader>

                                {userNavigation.map(({ href, label, hasDivider, browserLink }) => (
                                    <Fragment key={href}>
                                        <ListItemButton
                                            component={browserLink ? 'a' : Link}
                                            href={href}
                                        >
                                            <ListItemText>{label}</ListItemText>
                                        </ListItemButton>
                                        {hasDivider && <Divider />}
                                    </Fragment>
                                ))}
                                <Divider />
                            </Fragment>
                        ) : (
                            <ListItemButton component={Link} href="/auth/sign-in/">
                                <ListItemIcon>
                                    <AccountCircle />
                                </ListItemIcon>
                                <ListItemText>
                                    <FormattedMessage defaultMessage="Sign In" id="Ub+AGc" />
                                </ListItemText>
                            </ListItemButton>
                        )}
                    </List>
                </Drawer>
            </ThemeProvider>
        </AppBar>
    );
};

export default Navigation;
