import { FormLabel, Checkbox, SvgIcon, alpha } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Label = styled(FormLabel)(({ theme }) => ({
    display: 'flex',
    flexGrow: 1,
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
}));

export const CheckboxStyled = styled(Checkbox)(
    ({ theme }) => ({
        position: 'absolute',
        top: '10px',
        left: '10px',
        zIndex: 1,
        background: alpha(theme.palette.grey[100], 0.5),
        opacity: 0,
        transition: 'all 0.1s ease-in-out',
    }),
    `
    ${Label}:hover &,
    &:hover,
    &:has(:checked) {
        opacity: 1;
    }

    ${SvgIcon} {
        fill: white;
    }
`
);
