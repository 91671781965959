/*
 * A function that simulates the behavior of the following function,
 * but attempts to maintain the type of the input value:
 *
 * const filterFalsy = (value) => Boolean(value)
 *
 * And can be used like `arr.filter(Boolean)`:
 *
 * const truthy = arr.filter(filterFalsy)
 *
 */
export function filterNullNodes<T extends { node: T['node'] | null | undefined }>(
    edge: T
): edge is T & { node: Exclude<T['node'], undefined | null | '' | 0> } {
    return edge.node !== null && typeof edge.node !== 'undefined';
}
