/**
 * @generated SignedSource<<a1deadb01d718b9d281f78bf150d387d>>
 * @relayHash 3073f1acc15e4799c28ad66a63f654e0
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 3073f1acc15e4799c28ad66a63f654e0

import { ConcreteRequest, Query } from 'relay-runtime';
export type AppQueryQuery$variables = {
  hasUserEntityId: boolean;
  userEntityId: number;
};
export type AppQueryQuery$data = {
  readonly user?: {
    readonly email: string | null | undefined;
    readonly entityId: number;
    readonly firstName: string;
    readonly fullName: string;
    readonly lastName: string;
  } | null | undefined;
};
export type AppQueryQuery = {
  response: AppQueryQuery$data;
  variables: AppQueryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasUserEntityId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userEntityId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "entityId",
    "variableName": "userEntityId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppQueryQuery",
    "selections": [
      {
        "condition": "hasUserEntityId",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AppQueryQuery",
    "selections": [
      {
        "condition": "hasUserEntityId",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "id": "3073f1acc15e4799c28ad66a63f654e0",
    "metadata": {},
    "name": "AppQueryQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "6070fb7df8e70a630682546ab07f50f9";

export default node;
