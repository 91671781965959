/**
 * @generated SignedSource<<d10ecb7c27907a35c7cfc9c0a643fb5d>>
 * @relayHash f14f372264d4456a4e91e7e28d28713f
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f14f372264d4456a4e91e7e28d28713f

import { ConcreteRequest, Query } from 'relay-runtime';
export type FeatureFlagProviderQuery$variables = Record<PropertyKey, never>;
export type FeatureFlagProviderQuery$data = {
  readonly featureFlagsForUser: ReadonlyArray<{
    readonly isActive: boolean;
    readonly key: string;
  }>;
};
export type FeatureFlagProviderQuery = {
  response: FeatureFlagProviderQuery$data;
  variables: FeatureFlagProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isActive",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FeatureFlagProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FeatureFlag",
        "kind": "LinkedField",
        "name": "featureFlagsForUser",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FeatureFlagProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FeatureFlag",
        "kind": "LinkedField",
        "name": "featureFlagsForUser",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "f14f372264d4456a4e91e7e28d28713f",
    "metadata": {},
    "name": "FeatureFlagProviderQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "b363eff05190be836fd0692406808d43";

export default node;
