import { v1 as uuidv1 } from 'uuid';
import React, { FC, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Snackbar, Alert, AlertTitle, AlertColor } from '@mui/material';

type Notification = {
    type?: AlertColor;
    header?: ReactNode;
    body: ReactNode;
    timeout?: number;
};

type NotificationMap = Map<string, Notification>;
type NotificationList = [string, Notification][];

type NotificationsContext = {
    show: (notification: Notification) => void;
    hide: (key: string) => void;
    notificationList: NotificationList;
};

const NotificationsContext = React.createContext<NotificationsContext | null>(null);

export const useNotifications = () => {
    const ctx = useContext(NotificationsContext);

    if (!ctx) {
        throw Error(
            'The `useNotifications` hook must be called from a descendent of the `NotificationsProvider`.'
        );
    }

    return {
        show: ctx.show,
        hide: ctx.hide,
    };
};

const Notification: FC<{ notificationKey: string; notification: Notification }> = ({
    notificationKey,
    notification,
}) => {
    const ctx = useContext(NotificationsContext);
    const timeoutRef = useRef(notification.timeout ?? 3000);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => setIsVisible(true), []);
    useEffect(() => {
        if (!timeoutRef.current) {
            return;
        }

        let timeoutId: string | number | NodeJS.Timeout | undefined = undefined;
        if (isVisible) {
            timeoutId = setTimeout(() => {
                ctx?.hide(notificationKey);
            }, timeoutRef.current);
        }

        return () => clearTimeout(timeoutId);
    }, [ctx, notificationKey, isVisible]);

    return (
        <Snackbar onClose={() => setIsVisible(false)} open={isVisible}>
            <Alert severity={notification.type}>
                {notification.header && <AlertTitle>{notification.header}</AlertTitle>}
                {notification.body}
            </Alert>
        </Snackbar>
    );
};

const NotificationsContainer = () => {
    const ctx = useContext(NotificationsContext);

    return (
        <>
            {ctx?.notificationList &&
                ctx.notificationList.map(([key, notification]) => (
                    <Notification key={key} notificationKey={key} notification={notification} />
                ))}
        </>
    );
};

const notificationMap: NotificationMap = new Map();

export const NotificationsProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [_notificationList, setNotificationList] = useState<NotificationList>([]);

    const show = useCallback((notification: Notification) => {
        notificationMap.set(uuidv1(), notification);
        setNotificationList([...notificationMap.entries()]);
    }, []);

    const hide = useCallback((key: string) => {
        notificationMap.delete(key);
        setNotificationList([...notificationMap.entries()]);
    }, []);

    return (
        <>
            <NotificationsContext.Provider
                value={{
                    show,
                    hide,
                    notificationList: _notificationList,
                }}
            >
                {children}
                <NotificationsContainer />
            </NotificationsContext.Provider>
        </>
    );
};
