import { Components, Theme } from '@mui/material';

export const printingComponentsTheme = (): Components<Theme> => {
    return {
        MuiCssBaseline: {
            styleOverrides: {
                '@media print': {
                    body: {
                        '*': {
                            color: 'black !important',
                        },
                    },
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '@media print': {
                        backgroundColor: theme.palette.grey[600],
                    },
                }),
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '@media print': {
                        backgroundColor: theme.palette.common.white,
                        boxShadow: 'none',
                    },
                }),
            },
        },
        MuiCard: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '@media print': {
                        border: `1px solid ${theme.palette.grey[600]}`,
                        boxShadow: 'none',
                    },
                }),
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '@media print': {
                        backgroundColor: theme.palette.grey[300],
                    },
                }),
            },
        },
        MuiTable: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '@media print': {
                        border: `1px solid ${theme.palette.grey[600]}`,
                    },
                }),
            },
        },
        MuiTableContainer: {
            styleOverrides: {
                root: () => ({
                    '@media print': {
                        overflow: 'visible',
                    },
                }),
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                root: () => ({
                    '@media print': {
                        display: 'none',
                    },
                }),
            },
        },
    };
};
