import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import CookieConsent from '@ktech/cookie-jar/CookieConsent';
import { FormattedMessage } from 'react-intl';

const Footer = () => (
    <Box display="flex" flexGrow={0} flexShrink={0} alignItems="center" height={50} boxShadow={5}>
        <Container maxWidth="xxl">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="body2">
                    © KumiteTechnology {new Date().getFullYear()}
                </Typography>

                <Stack direction="row" alignItems="center" gap={2}>
                    <Typography variant="body2">
                        <Link href="/docs" target="_blank">
                            <FormattedMessage defaultMessage="Documentation" id="isGKnz" />
                        </Link>
                    </Typography>
                    <CookieConsent />
                </Stack>
            </Stack>
        </Container>
    </Box>
);

export default Footer;
