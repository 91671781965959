import { useTheme } from '@mui/material';
import { CSSProperties, FC } from 'react';

type Props = {
    style?: CSSProperties;
};

const KTLogo: FC<Props> = ({ style }) => {
    const theme = useTheme();
    const textColor = theme.palette.mode === 'dark' ? '#FFFFFF' : '#333333';
    return (
        <svg viewBox="0 0 309.3 97.7" style={style}>
            <g>
                <linearGradient
                    id="XMLID_19_"
                    gradientUnits="userSpaceOnUse"
                    x1="10.098"
                    y1="44.6293"
                    x2="94.8279"
                    y2="44.6293"
                >
                    <stop offset="0" style={{ stopColor: '#6F2875' }} />
                    <stop offset="1" style={{ stopColor: '#ED335C' }} />
                </linearGradient>
                <path
                    fill="url(#XMLID_19_)"
                    d="M28.9,15.2L11.4,45.5c-1.6,2.7-1.6,6,0,8.7l14,24.2l10.8-67.6C33.2,10.9,30.5,12.6,28.9,15.2z"
                />
                <linearGradient
                    id="XMLID_20_"
                    gradientUnits="userSpaceOnUse"
                    x1="10.098"
                    y1="69.1214"
                    x2="94.8279"
                    y2="69.1214"
                >
                    <stop offset="0" style={{ stopColor: '#6F2875' }} />
                    <stop offset="1" style={{ stopColor: '#ED335C' }} />
                </linearGradient>
                <path
                    id="XMLID_27_"
                    fill="url(#XMLID_20_)"
                    d="M37.2,88.9h34.3c2.5,0,4.8-1.1,6.4-2.8L43.5,49.4L37.2,88.9z"
                />
                <linearGradient
                    id="XMLID_21_"
                    gradientUnits="userSpaceOnUse"
                    x1="10.098"
                    y1="28.1254"
                    x2="94.8279"
                    y2="28.1254"
                >
                    <stop offset="0" style={{ stopColor: '#6F2875' }} />
                    <stop offset="1" style={{ stopColor: '#ED335C' }} />
                </linearGradient>
                <path
                    fill="url(#XMLID_21_)"
                    d="M80.7,18.1L79,15.2c-1.6-2.7-4.4-4.4-7.6-4.4H49.7l-5.6,34.6L80.7,18.1z"
                />
                <linearGradient
                    id="XMLID_22_"
                    gradientUnits="userSpaceOnUse"
                    x1="10.098"
                    y1="48.7835"
                    x2="94.8279"
                    y2="48.7835"
                >
                    <stop offset="0" style={{ stopColor: '#6F2875' }} />
                    <stop offset="1" style={{ stopColor: '#ED335C' }} />
                </linearGradient>
                <path
                    fill="url(#XMLID_22_)"
                    d="M96.5,45.5L83.4,22.7L55.3,43.6l29.4,31.2l11.9-20.6C98.1,51.5,98.1,48.2,96.5,45.5z"
                />
                <path
                    fill="#FFFFFF"
                    d="M55.3,43.6l28.1-20.9l-2.7-4.6L44.1,45.4l5.6-34.6l-13.4,0L25.4,78.4c0,0,5.7,10.5,10.5,10.5
            h1.3l6.3-39.5l34.4,36.7l6.7-11.2L55.3,43.6z"
                />
            </g>
            <g id="XMLID_75_">
                <path
                    fill={textColor}
                    d="M122.5,37h-3l-3.1,15.9h-7.7l7.7-38.5h7.7l-3.1,15.7h3l10.6-15.7h10l-15.1,19.2l7.8,19.3h-8.8
            L122.5,37z"
                />
                <path
                    fill={textColor}
                    d="M176.5,14.4L171.4,40c-1,4.7-2.9,8-6,10.2c-3,2.2-6.8,3.2-11.3,3.2c-4,0-7.3-1.2-9.5-3.6
            s-3-5.7-2.2-10l5.1-25.6h7.7l-5.1,25.6c-0.5,2.7-0.3,4.7,0.6,5.8c1,1.2,2.5,1.7,4.7,1.7c2.1,0,3.8-0.6,5.4-1.9
            c1.5-1.3,2.6-3.1,3-5.6l5.1-25.6h7.5V14.4z"
                />
                <path
                    fill={textColor}
                    d="M192.7,14.4l3,28.4h0.1l14.5-28.4h10L212.6,53h-7.6l2.3-11.5l3.9-16.1h-0.1L196.4,53h-5.1
            l-3.6-27.6h-0.1L185,41.5L182.8,53h-7.6l7.7-38.5h9.9V14.4z"
                />
                <path fill={textColor} d="M227.4,53h-7.6l7.7-38.5h7.6L227.4,53z" />
                <path
                    fill={textColor}
                    d="M265.1,20.4h-10L248.6,53H241l6.5-32.6h-9.9l1.2-6h27.4L265.1,20.4z"
                />
                <path
                    fill={textColor}
                    d="M288.2,36h-14.6l-2.2,10.9h17.1l-1.2,6h-24.7l7.7-38.5h24.7l-1.2,6h-17l-1.9,9.6h14.6
            L288.2,36z"
                />
                <path
                    fill={textColor}
                    d="M118.7,66.1h-7.2l-4.1,20.8h-1.8l4.1-20.8h-7.1l0.3-1.6h16L118.7,66.1z"
                />
                <path
                    fill={textColor}
                    d="M131.9,76.1h-10l-1.9,9.2h11.4l-0.3,1.6H118l4.5-22.3h13.1l-0.3,1.6h-11.4l-1.7,8.4h10
            L131.9,76.1z"
                />
                <path
                    fill={textColor}
                    d="M150.6,80.1L150.6,80.1c-0.6,2.3-1.6,4.1-3,5.3c-1.4,1.2-3.1,1.8-5.3,1.8s-4-0.9-5.2-2.8
            c-1.3-1.9-1.6-4.1-1.1-6.9l0.7-3.6c0.6-3.1,1.7-5.4,3.3-7.1c1.6-1.7,3.5-2.5,5.8-2.5c2.1,0,3.8,0.6,5,1.9c1.3,1.3,1.8,3.1,1.4,5.3
            v0.1h-1.7c0.1-1.8-0.2-3.3-1.1-4.3c-0.9-1-2.2-1.5-4-1.5c-1.6,0-3.1,0.8-4.5,2.3s-2.2,3.4-2.7,5.8l-0.8,3.7c-0.5,2.5-0.3,4.5,0.6,6
            c0.9,1.5,2.3,2.2,4.1,2.2c1.6,0,2.9-0.4,4-1.4c1.1-0.9,1.9-2.3,2.4-4.2C148.8,80.1,150.6,80.1,150.6,80.1z"
                />
                <path
                    fill={textColor}
                    d="M169.1,86.9h-1.8l2.1-10.3h-11.7l-2.1,10.3h-1.8l4.5-22.3h1.8L158,75.1h11.7l2.1-10.5h1.8
            L169.1,86.9z"
                />
                <path
                    fill={textColor}
                    d="M189.9,86.9h-1.8l-7.8-19.1h-0.1l-3.8,19.1h-1.8l4.5-22.3h1.8l7.8,19h0.1l3.8-19h1.8
            L189.9,86.9z"
                />
                <path
                    fill={textColor}
                    d="M212.5,77.6c-0.6,3.1-1.8,5.5-3.4,7.1c-1.6,1.6-3.7,2.4-6.1,2.4c-2.3,0-4.1-0.9-5.5-2.8
            c-1.3-1.8-1.7-4.1-1.1-7l0.7-3.6c0.6-3.1,1.8-5.5,3.3-7.1c1.6-1.6,3.6-2.4,6.1-2.4c2.3,0,4.2,0.9,5.5,2.8c1.3,1.8,1.8,4.1,1.2,7
            L212.5,77.6z M211.5,74c0.5-2.5,0.3-4.5-0.7-6c-1-1.4-2.5-2.1-4.6-2.1c-1.8,0-3.3,0.8-4.6,2.2c-1.3,1.4-2.2,3.4-2.6,5.8l-0.8,3.7
            c-0.5,2.6-0.3,4.5,0.6,6c0.9,1.4,2.4,2.1,4.5,2.1c1.8,0,3.5-0.8,4.8-2.2c1.3-1.4,2.3-3.4,2.7-5.8L211.5,74z"
                />
                <path fill={textColor} d="M217.4,85.3h10.2l-0.3,1.6h-12l4.5-22.3h1.8L217.4,85.3z" />
                <path
                    fill={textColor}
                    d="M246.9,77.6c-0.6,3.1-1.8,5.5-3.4,7.1c-1.6,1.6-3.7,2.4-6.1,2.4c-2.3,0-4.1-0.9-5.5-2.8
            c-1.3-1.8-1.7-4.1-1.1-7l0.7-3.6c0.6-3.1,1.8-5.5,3.3-7.1c1.6-1.6,3.6-2.4,6.1-2.4c2.3,0,4.2,0.9,5.5,2.8c1.3,1.8,1.8,4.1,1.2,7
            L246.9,77.6z M245.8,74c0.5-2.5,0.3-4.5-0.7-6c-1-1.4-2.5-2.1-4.6-2.1c-1.8,0-3.3,0.8-4.6,2.2c-1.3,1.4-2.2,3.4-2.6,5.8l-0.8,3.7
            c-0.5,2.6-0.3,4.5,0.6,6c0.9,1.4,2.4,2.1,4.5,2.1c1.8,0,3.5-0.8,4.8-2.2c1.3-1.4,2.3-3.4,2.7-5.8L245.8,74z"
                />
                <path
                    fill={textColor}
                    d="M265,84.2c-0.6,0.8-1.6,1.4-2.9,2c-1.4,0.6-2.9,0.9-4.8,0.9c-2.3,0-4-0.9-5.3-2.7
            c-1.3-1.8-1.6-4.1-1.1-6.8l0.8-4.1c0.6-2.9,1.8-5.2,3.6-6.9c1.8-1.6,3.8-2.4,6.2-2.4c2.1,0,3.7,0.6,4.8,1.8s1.6,2.8,1.4,4.6
            l-0.1,0.1h-1.6c0.1-1.5-0.3-2.7-1-3.6c-0.7-0.9-1.9-1.4-3.8-1.4c-1.7,0-3.3,0.8-4.8,2.2c-1.5,1.5-2.4,3.3-2.9,5.5l-0.8,4.1
            c-0.5,2.5-0.3,4.5,0.6,5.8c0.9,1.4,2.3,2.1,4.3,2.1c1.3,0,2.4-0.2,3.5-0.6s1.9-0.9,2.3-1.3l1.2-5.8H259l0.3-1.6h7.2L265,84.2z"
                />
                <path
                    fill={textColor}
                    d="M277.9,76.9l8.8-12.3h2.1l-10.4,14.2l-1.6,8.2H275l1.6-8.3l-4.6-14.1h2.1L277.9,76.9z"
                />
            </g>
        </svg>
    );
};

export default KTLogo;
