import { FC, useContext } from 'react';
import { GoogleAnalytics } from '@next/third-parties/google';
import { CookieConsetContext } from '@ktech/cookie-jar/CookieConsent';

const GoogleAnalyticsLoader: FC = () => {
    const cookieConsent = useContext(CookieConsetContext);

    if (cookieConsent.analytics && process.env.NEXT_PUBLIC_GA_ID) {
        return <GoogleAnalytics gaId={process.env.NEXT_PUBLIC_GA_ID} />;
    }

    return null;
};

export default GoogleAnalyticsLoader;
