import { FC, KeyboardEvent, FocusEvent, useState, useEffect, ReactNode } from 'react';
import isEqual from 'lodash.isequal';
import { Box, TextField, Chip, IconButton, InputAdornment } from '@mui/material';
import Close from '@mui/icons-material/Close';

type Value = { entityId: number; name: string };
type NewValue = { timestamp: number; name: string };

type Props = {
    label?: ReactNode;
    helperText?: ReactNode;
    size?: 'small' | 'medium';
    disabled?: boolean;
    values: Value[];
    onChange: (payload: {
        values: Value[];
        newValues: string[];
        removedEntityIds: number[];
    }) => void;
};

export const MultiInput: FC<Props> = ({
    label,
    helperText,
    size,
    values: _values,
    onChange,
    disabled,
}) => {
    const [initialValues, setInitialValues] = useState<Value[]>(_values);

    const [newValues, setNewValues] = useState<NewValue[]>([]);
    const [removedEntityIds, setRemovedValues] = useState<number[]>([]);
    const [previewValues, setPreviewValues] = useState<(Value | NewValue)[]>([
        ...initialValues,
        ...newValues,
    ]);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (!isEqual(initialValues, _values)) {
            setInitialValues(_values);
            setNewValues([]);
            setRemovedValues([]);
        }
    }, [initialValues, _values]);

    useEffect(() => {
        setPreviewValues([
            ...initialValues.filter(v => !removedEntityIds.includes(v.entityId)),
            ...newValues,
        ]);
    }, [initialValues, newValues, removedEntityIds]);

    const setNewValue = (value: string) => {
        if (!value) {
            return;
        }

        const valueArr = value
            .split(',')
            .map(v => v.trim())
            .filter(Boolean);

        const timestamp = new Date().getTime();
        const _newValues = [
            ...newValues,
            ...valueArr.map((name, index) => ({ timestamp: timestamp + index, name })),
        ];

        setNewValues(_newValues);
        setInputValue('');

        onChange({
            values: initialValues,
            newValues: _newValues.map(v => v.name),
            removedEntityIds,
        });
    };

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;

        switch (e.key) {
            case 'Backspace':
                if (!value) {
                    const removedValue = previewValues.pop();
                    onRemove(removedValue);
                }
                break;
            case ',':
                e.preventDefault();
            // eslint-disable-next-line
            case 'Tab':
            case 'Enter':
                if (value && e.key === 'Enter') {
                    e.preventDefault();
                }
                setNewValue(value);
                break;
        }
    };

    const onBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
        setNewValue(e.currentTarget.value);
    };

    const onRemove = (value: Value | NewValue | undefined) => {
        if (!value) {
            return;
        }

        if ('timestamp' in value) {
            const _newValues = newValues.filter(
                v => !('timestamp' in v) || value.timestamp !== v.timestamp
            );
            setNewValues(_newValues);
            onChange({
                values: initialValues,
                newValues: _newValues.map(v => v.name),
                removedEntityIds,
            });
        } else {
            const _removedENtityIds = [...removedEntityIds, value.entityId];
            setRemovedValues(_removedENtityIds);
            onChange({
                values: initialValues,
                newValues: newValues.map(v => v.name),
                removedEntityIds: _removedENtityIds,
            });
        }
    };

    return (
        <TextField
            label={label}
            size={size}
            onChange={e => setInputValue(e.target.value)}
            helperText={helperText}
            inputProps={{
                onKeyDown,
                onBlur,
            }}
            value={inputValue}
            disabled={disabled}
            fullWidth
            InputProps={{
                startAdornment: previewValues.length ? (
                    <InputAdornment
                        position="start"
                        component={Box}
                        display="flex"
                        mr={previewValues.length ? 1 : 0}
                        flexWrap="wrap"
                        flex="1 0 auto"
                        maxWidth="80%"
                    >
                        <>
                            {previewValues.map((value, index) => (
                                <Chip
                                    key={index}
                                    size="small"
                                    color="primary"
                                    sx={{ transform: 'translateY(-50%)', mr: 1 }}
                                    label={
                                        <>
                                            {value.name}
                                            <IconButton
                                                size="small"
                                                tabIndex={-1}
                                                color="inherit"
                                                onClick={() => onRemove(value)}
                                            >
                                                <Close
                                                    color="inherit"
                                                    sx={{ width: '0.6em', height: '0.6em' }}
                                                />
                                            </IconButton>
                                        </>
                                    }
                                ></Chip>
                            ))}
                        </>
                    </InputAdornment>
                ) : undefined,
            }}
        />
    );
};

export default MultiInput;
